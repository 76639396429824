<template>
    <div class="home">
      <el-menu :default-active="formInline.order_type+''" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">服务费</el-menu-item>
        <el-menu-item index="2">打赏</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="交易日期">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="支付渠道">
            <el-select style="width:200px;"  v-model="status" placeholder="请选择">
              <el-option
                    label="请选择"
                    :value="''">
                  </el-option>
                  <el-option
                    v-for="pc in payChannel"
                    :key="pc.value"
                    :label="pc.name"
                    :value="pc.value">
                  </el-option>
            </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="请输入商户订单号/订单号/手机/用户名"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            <el-button  type="info"  @click="exportlist">导出</el-button>
            </el-form-item>
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="out_trade_no"
          width="200"
          label="商户订单号">
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="200"
          label="投诉订单编号">
        </el-table-column>
        <el-table-column
          prop="order_group_text"
          label="订单类型">
        </el-table-column>
        <el-table-column
          prop="name"
          label="用户名">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="order_type_text"
          label="支付种类">
        </el-table-column>
        <el-table-column
          prop="payment" 
          label="订单金额">
        </el-table-column>
        <el-table-column
          prop="paydate"
          label="支付时间">
          <template  slot-scope="scope">
            {{scope.row.paydate | formatDate2}}
         </template>
        </el-table-column>
        <el-table-column
          prop="payment_channel_text"
          label="支付渠道">
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          :page-sizes="[10, 20, 30, 40 ,50]"
          layout="sizes,total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'
import globla from '../../globla'

export default {
  name: 'Home',
  data(){
    return {
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      payChannel:[],//支付渠道
      formInline: {
         sdate:'',//开始日期
         edate:'',//结束日期
         payment_channel:0,//支付渠道
         search_data:'',//检索内容
         order_type:0,//类型 0全部 1服务费2打赏 
      },
      status:'',//支付渠道
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         sdate:'',//	开始日期
         edate:'',//结束日期
         payment_channel:'',//支付渠道
         search_data:'',//检索内容
         order_type:0,//类型 0全部 1服务费2打赏 
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate2(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      exportlist(){
     
        let _this = this;
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(_this.timevalue){
            _this.formInline.sdate = formatDate(_this.timevalue[0]);
            _this.formInline.edate = formatDate(_this.timevalue[1]);
          }
          _this.formInline.payment_channel = _this.status?_this.status:'';
          _this.formInline.next_processing_status = _this.status?_this.status:'';
          
          location.href= `${globla.SERVE_URL+'/'+config.sclistExport}?order_id=${_this.ghs.toString() ? _this.ghs.toString() : ''}&sdate=${_this.formInline.sdate}
          &edate=${_this.formInline.edate}&payment_channel=${_this.formInline.payment_channel}&search_data=${_this.formInline.search_data}
          &order_type=${_this.formInline.order_type}&api_token=${localStorage.getItem('Authorization')}`;
       
       
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.limit = _this.per_page;
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(_this.timevalue){
            _this.formInline.sdate = formatDate(_this.timevalue[0]);
            _this.formInline.edate = formatDate(_this.timevalue[1]);
          }
          _this.formInline.payment_channel = _this.status?_this.status:'';
        axios.get(config.sclist,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page * 1;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleSizeChange(val){
        this.per_page = val*1;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.sclist,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.pay_channel)
        .then(function (response) {
            if(response.data.code == 200){
              _this.payChannel = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>